import { ToastState } from "../util/IonicExt";

export default interface ToastHook {
  toast: (toastOptions: ToastState) => void;
}

export type ToastHookInternals = {
  toast: (toastOptions: ToastState) => void;
};

export function useToastHook(
  toastHookInternals: ToastHookInternals,
): ToastHook {
  return {
    toast: (toastOptions) => {
      toastHookInternals.toast(toastOptions);
    },
  };
}
