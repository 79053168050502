import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { RangeValue } from "@ionic/core";
import { IonRange } from "@ionic/react";
import Theme from "../data/Theme";

export default function MatchSlider({
  index,
  max,
  setMatchIndex,
  theme,
}: MatchSliderProps) {
  return (
    <div className="flex h-12 w-full flex-row items-center justify-center">
      <IonRange
        mode="ios"
        min={0}
        max={max}
        style={{
          "--knob-background": theme.c0000,
          "--bar-background": theme.c0100,
          "--bar-background-active": theme.c0100,
          "--knob-size": "40px",
        }}
        value={index}
        snaps={true}
        ticks={true}
        step={1}
        onIonKnobMoveStart={async () => {
          await Haptics.impact({ style: ImpactStyle.Light });
        }}
        onIonKnobMoveEnd={async () => {
          await Haptics.impact({ style: ImpactStyle.Medium });
        }}
        onIonChange={({ detail }) => {
          setMatchIndex(detail.value);
        }}
      />
    </div>
  );
}

type MatchSliderProps = {
  index: RangeValue;
  max: number;
  setMatchIndex: (index: RangeValue) => void;
  theme: Theme;
};
