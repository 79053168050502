import { HttpResponse } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { IonButton, IonChip, IonLabel, IonPage, IonText } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { JIBI_BASE_URL, PATCH } from "../../../util/ApiClient";
import { UserContext } from "../../../util/BetterDatesApp";
import { handleError } from "../../../util/error";

export default function LocationPreferences() {
  const userContext = useContext(UserContext);
  const history = useHistory();
  if (!userContext?.userState?.loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;
  const [state, setState] = useState<LocationStateType>({
    detected: false,
    reason: "idle",
  });
  const [inProgress, setInProgress] = useState(false);
  const hasRequestedToUpdate = useRef(false);
  useEffect(() => {
    if (state.detected && !hasRequestedToUpdate.current) {
      updateUsersLocation(state.latitude, state.longitude, savedUser.token)
        .then((result) => {
          if (result.status !== 200) {
            return Promise.reject(result);
          }
          const user = {
            ...savedUser.user,
            location: result.data.location,
          };
          return userContext?.loginHook.saveUser({
            ...savedUser,
            user: user,
          });
        })
        .then(() => history.goBack())
        .catch((e) => handleError(e, userContext))
        .finally(() => {
          hasRequestedToUpdate.current = false;
        });
    }
    return () => {
      if (state.detected) {
        hasRequestedToUpdate.current = true;
      }
    };
  }, [state]);
  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          <header className="flex flex-col items-center gap-8 px-8 py-8">
            <div className="w-full">
              <IonChip
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
                <IonLabel>Back</IonLabel>
              </IonChip>
            </div>
            <div className="flex w-full flex-row items-start">
              <IonText className="text-2xl font-bold">Location</IonText>
            </div>
          </header>
          <main className="h-full w-full">
            <div className="flex h-full w-full flex-col justify-between px-8 pb-8">
              <div className="flex w-full flex-col gap-4">
                <IonText>
                  {!state.detected &&
                    state.reason === "idle" &&
                    "You should grant location permission in order to match you with the people in your city."}
                  {!state.detected &&
                    state.reason === "rejected" &&
                    "In order to continue, you should grant the app to access your location so we can match you with the people in your city."}
                  {!state.detected &&
                    state.reason === "error" &&
                    "There was a problem when processing your request. Please try again."}
                  {state.detected && "Processing..."}
                </IonText>
                <div className="flex w-full flex-row"></div>
              </div>
              <div className="flex w-full flex-row justify-end">
                <IonButton
                  className={"mt-8 h-9 w-20"}
                  shape={"round"}
                  color={"dark"}
                  size={"small"}
                  hidden={state.detected}
                  disabled={
                    (!state.detected && state.reason === "rejected") ||
                    inProgress
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setInProgress(true);
                    Geolocation.checkPermissions()
                      .then((result) => result.location)
                      .then((permission) => {
                        switch (permission) {
                          case "denied":
                            setState({
                              detected: false,
                              reason: "rejected",
                            });
                            return Promise.reject(permission);
                          default:
                            return Geolocation.getCurrentPosition();
                        }
                      })
                      .then((position) => {
                        const coordinate = position.coords;
                        setState({
                          detected: true,
                          latitude: coordinate.latitude.toString(),
                          longitude: coordinate.longitude.toString(),
                        });
                      })
                      .catch((e) => {
                        if (
                          e.code === GeolocationPositionError.PERMISSION_DENIED
                        ) {
                          setState({ detected: false, reason: "rejected" });
                          userContext?.errorHook.logError("I", e);
                        } else {
                          handleError(e, userContext);
                        }
                      })
                      .finally(() => {
                        setInProgress(false);
                      });
                  }}
                >
                  {!state.detected &&
                    (state.reason === "idle" || state.reason === "rejected") &&
                    "Proceed"}
                  {!state.detected && state.reason === "error" && "Retry"}
                </IonButton>
              </div>
            </div>
          </main>
        </div>
      </div>
    </IonPage>
  );
}

interface LocationState {
  detected: boolean;
}

interface LocationDetectedState extends LocationState {
  detected: true;
  latitude: string;
  longitude: string;
}

interface LocationNotDetectedState extends LocationState {
  detected: false;
  reason: NotDetectedReason;
}

type LocationStateType = LocationDetectedState | LocationNotDetectedState;

type NotDetectedReason = "idle" | "rejected" | "error";

function updateUsersLocation(
  latitude: string,
  longitude: string,
  token: string,
): Promise<HttpResponse> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/user/location`,
    headers: {
      Authorization: token,
    },
    body: {
      latitude: latitude,
      longitude: longitude,
    },
  });
}
