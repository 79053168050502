export interface PreferencesData {
  gender: string[] | undefined;
  description: string | undefined;
}

export function emptyPreferencesData(): PreferencesData {
  return {
    gender: undefined,
    description: undefined,
  };
}
