import { HttpResponse } from "@capacitor/core";
import {
  IonAlert,
  IonButton,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonText,
} from "@ionic/react";
import { useContext, useState } from "react";
import { JIBI_BASE_URL, PATCH } from "../../../util/ApiClient";
import { UserContext } from "../../../util/BetterDatesApp";
import { calculateAge } from "../../../util/DateExt";
import { handleError } from "../../../util/error";

export default function AgeInformation(props: { onNext: () => void }) {
  const userContext = useContext(UserContext);
  if (!userContext?.userState?.loggedIn) {
    props.onNext();
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;
  const defaultDate = new Date("1995-06-01");
  const [date, setDate] = useState<Date>(defaultDate);
  const [isAgeConfirmationOpen, setIsAgeConfirmationOpen] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  return (
    <div className="flex h-full w-full flex-col justify-between px-8 pb-4">
      <div className="flex w-full flex-col gap-4">
        <IonText>We use this information to confirm your age.</IonText>
        <div className="flex w-full flex-row">
          <IonDatetimeButton datetime={"datetime"} />
          <IonModal keepContentsMounted={true}>
            <IonDatetime
              id="datetime"
              presentation={"date"}
              preferWheel={true}
              value={date.toISOString()}
              showDefaultButtons={true}
              onIonChange={({ detail }) => {
                setDate(new Date(detail.value as string));
              }}
            />
          </IonModal>
          <IonAlert
            isOpen={isAgeConfirmationOpen}
            id="ageAlert"
            header="Confirmation"
            message={`Are you ${calculateAge(date)} years old?`}
            buttons={[
              {
                text: "NO",
                handler: () => {
                  setInProgress(false);
                  setIsAgeConfirmationOpen(false);
                },
              },
              {
                text: "YES",
                handler: () => {
                  setInProgress(true);
                  const birthday = formatDate(date);
                  updateUsersAge(birthday, savedUser?.token)
                    .then((result) => {
                      if (result.status !== 200) {
                        return Promise.reject(result);
                      }
                      const user = {
                        ...savedUser.user,
                        birthday: new Date(birthday),
                      };
                      return userContext?.loginHook.saveUser({
                        ...savedUser,
                        user: user,
                      });
                    })
                    .then(() => props.onNext())
                    .catch((e) => handleError(e, userContext))
                    .finally(() => {
                      setInProgress(false);
                    });
                },
              },
            ]}
            onDidDismiss={() => {
              setIsAgeConfirmationOpen(false);
            }}
          />
        </div>
      </div>
      <div className="flex w-full flex-row justify-end">
        <IonButton
          className={"mt-8 h-9 w-20"}
          disabled={inProgress}
          shape={"round"}
          color={"dark"}
          size={"small"}
          onClick={(e) => {
            e.preventDefault();
            setInProgress(true);
            setIsAgeConfirmationOpen(true);
          }}
        >
          Next
        </IonButton>
      </div>
    </div>
  );
}

function formatDate(date: Date) {
  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, "0");
  };
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("-");
}

function updateUsersAge(
  birthday: string,
  token: string,
): Promise<HttpResponse> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/user/age`,
    headers: {
      Authorization: token,
    },
    body: {
      birthday: birthday,
    },
  });
}
