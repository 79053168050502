import {
  CheckCircleIcon,
  ChevronLeftIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";
import {
  IonChip,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonPage,
  IonText,
} from "@ionic/react";
import { useContext } from "react";
import { UserContext } from "../../util/BetterDatesApp";
import {
  isGenderValid,
  isLocationValid,
  isMatchPreferencesDescriptionPresent,
  isMatchPreferencesGenderPresent,
  isSexualOrientationValid,
} from "../../validation/ProfileValidation";
import { Location } from "../../data/location";
import { useHistory } from "react-router";

export default function NewPreferencesFlow() {
  const history = useHistory();
  const userContext = useContext(UserContext);
  if (!userContext?.userState.loggedIn) {
    return;
  }
  const savedUser = userContext.userState.savedUser;
  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="safe-container flex h-full w-full max-w-md flex-col justify-between px-8">
        <header className="flex flex-row items-center py-8">
          <IonChip
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
            <IonLabel>Back</IonLabel>
          </IonChip>
        </header>
        <main className="safe-scroller flex h-full w-full flex-col">
          <IonText className="text-2xl font-bold">Preferences</IonText>
          <IonText className="text-sm text-gray-400">
            We don't show these information on your profile to your matches. We
            use these information to understand about you, so we can find your
            perfect match.
          </IonText>
          <IonList className="mt-4">
            <IonItem
              button
              detail
              onClick={(e) => {
                e.preventDefault();
                history.push("/change-location");
              }}
            >
              <CheckOrErrorIcon
                isValid={isLocationValid(savedUser.user).valid}
              />
              <IonLabel>Location</IonLabel>
              <IonNote slot="end">
                {getHumanReadableLocation(savedUser.user.location)}
              </IonNote>
            </IonItem>
            <IonItem
              button
              detail
              onClick={(e) => {
                e.preventDefault();
                history.push("/gender");
              }}
            >
              <CheckOrErrorIcon
                isValid={
                  isGenderValid(savedUser.user).valid &&
                  isMatchPreferencesGenderPresent(savedUser.user).valid
                }
              />
              <IonLabel>Gender</IonLabel>
            </IonItem>
            <IonItem
              button
              detail
              onClick={(e) => {
                e.preventDefault();
                history.push("/sexual-orientation");
              }}
            >
              <CheckOrErrorIcon
                isValid={isSexualOrientationValid(savedUser.user).valid}
              />
              <IonLabel>Sexual Orientation</IonLabel>
            </IonItem>
            <IonItem
              button
              detail
              onClick={(e) => {
                e.preventDefault();
                history.push("/preferences/description");
              }}
            >
              <CheckOrErrorIcon
                isValid={
                  isMatchPreferencesDescriptionPresent(savedUser.user).valid
                }
              />
              <IonLabel>What are you looking for</IonLabel>
            </IonItem>
          </IonList>
        </main>
      </div>
    </IonPage>
  );
}

function getHumanReadableLocation(location?: Location): string {
  if (!location) {
    return "";
  }
  if (location.city.length > 0 && location.country.length > 0) {
    return `${location.city}, ${location.country}`;
  }
  return `${location.city}${location.country}`;
}

function CheckOrErrorIcon(props: { isValid: boolean }) {
  return (
    <div slot="start">
      {props.isValid ? (
        <>
          <CheckCircleIcon
            className="h-5 w-5 text-green-500"
            aria-hidden="true"
          />
        </>
      ) : (
        <ExclamationCircleIcon
          className="h-5 w-5 text-red-500"
          aria-hidden="true"
        />
      )}
    </div>
  );
}
