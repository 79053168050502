import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { IonChip, IonContent, IonLabel, IonPage, IonText } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Letter from "../../data/Letter";
import { Match } from "../../data/Match";
import { InMemoryCache } from "../../hooks/CacheHook";
import { UserContext } from "../../util/BetterDatesApp";

export default function ReadLetter() {
  const params = useParams<ReadLetterProps>();
  const history = useHistory();
  const userContext = useContext(UserContext);
  if (!userContext?.userState.loggedIn) {
    return <></>;
  }
  const [state, setState] = useState<ReadLetterStateType>({ loading: true });
  const savedUser = userContext.userState.savedUser;

  useEffect(() => {
    const cachedMatch = getCachedMatch(userContext.cache, params.matchId);
    if (!cachedMatch) {
      history.replace("");
      return;
    }
    const cachedLetter = getCachedLetter(
      userContext.cache,
      params.matchId,
      params.letterId,
    );
    if (!cachedLetter) {
      history.replace("");
      return;
    }
    setState({ loading: false, match: cachedMatch, letter: cachedLetter });
  }, []);

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          <header className="flex h-14 flex-row items-center justify-between px-3">
            <IonChip
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
              <IonLabel>Back</IonLabel>
            </IonChip>
          </header>
          <main className="flex h-full w-full">
            {state.loading ? (
              <div className="flex h-full w-full items-center justify-center">
                <IonText>Loading...</IonText>
              </div>
            ) : (
              <div className="mt-20 flex w-full flex-col gap-4 px-10">
                <IonText>
                  <b>
                    {state.letter.from === savedUser.user.id
                      ? "you"
                      : state && !state.loading && state.match.partyTwo.name}
                    :
                  </b>
                </IonText>
                <IonContent>
                  <p
                    className="whitespace-pre-wrap pb-10 text-start text-black"
                    dir="auto"
                  >
                    {state.letter.letter}
                  </p>
                </IonContent>
              </div>
            )}
          </main>
        </div>
      </div>
    </IonPage>
  );
}

function getCachedMatch(
  cache: InMemoryCache,
  matchId: string,
): Match | undefined {
  const cachedMatches = cache.matches;
  return cachedMatches?.find((element) => element.id === matchId) || undefined;
}

function getCachedLetter(
  cache: InMemoryCache,
  matchId: string,
  letterId: string,
): Letter | undefined {
  const cachedLetters = cache.letters;
  const lettersFromMatch = cachedLetters.get(matchId);
  return (
    lettersFromMatch?.find((element) => element.id === letterId) || undefined
  );
}

interface ReadLetterState {
  loading: boolean;
}

interface ReadLetterStateLoading extends ReadLetterState {
  loading: true;
}

interface ReadLetterStateLoaded extends ReadLetterState {
  loading: false;
  match: Match;
  letter: Letter;
}

type ReadLetterStateType = ReadLetterStateLoading | ReadLetterStateLoaded;

export type ReadLetterProps = {
  matchId: string;
  letterId: string;
};
