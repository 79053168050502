import { Match, MatchId } from "../data/Match";
import { PreferencesData } from "../data/PreferencesData";
import Letter from "../data/Letter";
import { Config } from "../data/Config";

export default interface CacheHook {
  cacheMatches: (matches: Match[]) => void;
  updatePreferencesData: (preferencesData: PreferencesData) => void;
  cacheLetters: (matchId: MatchId, letters: Letter[]) => void;
}

export interface InMemoryCache {
  appConfig: Config;
  matches: Match[] | undefined;
  letters: Map<MatchId, Letter[]>;
  preferencesData: PreferencesData;
}

export type CacheHookInternals = {
  cacheMatches: (matches: Match[]) => void;
  updatePreferencesData: (preferencesData: PreferencesData) => void;
  cacheLetters: (matchId: MatchId, letters: Letter[]) => void;
};

export function useCacheHook(
  cacheHookInternals: CacheHookInternals,
): CacheHook {
  return {
    cacheMatches: (matches) => {
      cacheHookInternals.cacheMatches(matches);
    },
    updatePreferencesData: (preferencesData) => {
      cacheHookInternals.updatePreferencesData(preferencesData);
    },
    cacheLetters: (matchId, letters) => {
      cacheHookInternals.cacheLetters(matchId, letters);
    },
  };
}
