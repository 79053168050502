import { HttpResponse } from "@capacitor/core";
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonText,
} from "@ionic/react";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import Theme from "../../data/Theme";
import { SavedUser } from "../../hooks/LoginHook";
import UserContextHook from "../../hooks/UserContextHook";
import { JIBI_BASE_URL, POST } from "../../util/ApiClient";
import { UserContext } from "../../util/BetterDatesApp";
import { handleError } from "../../util/error";
import { isProfileComplete } from "../../validation/ProfileValidation";
import { MatchPreferences } from "../../data/match-preferences";
import { Location } from "../../data/location";

export default function EmailLogin() {
  const userContext = useContext(UserContext);
  if (!userContext) {
    return <></>;
  }
  const history = useHistory();
  const [emailInputState, setEmailInputState] = useState("");
  const [passwordInputState, setPasswordInputState] = useState("");
  const [inProgress, setInProgress] = useState(false);
  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          <header className="flex h-56 flex-row items-center px-8">
            <IonText className="text-2xl font-bold">Hi!</IonText>
          </header>
          <main className="flex h-full w-full flex-col justify-between px-8 pb-4">
            <div className="flex w-full flex-col gap-4">
              <IonInput
                placeholder="Enter your email address"
                type={"email"}
                autofocus={true}
                className="force-dark text-xl"
                value={emailInputState}
                onIonInput={(e) => {
                  const newInput = e.detail.value as string;
                  setEmailInputState(newInput);
                }}
              />
              <IonInput
                placeholder="Password"
                type={"password"}
                className="force-dark text-xl"
                value={passwordInputState}
                onIonInput={(e) => {
                  const newInput = e.detail.value as string;
                  setPasswordInputState(newInput);
                }}
              />
              <IonItem
                className="mt-4"
                button={true}
                lines={"none"}
                color="light"
                detail={true}
                onClick={() => {
                  history.replace("/phone-login");
                }}
              >
                <IonLabel>Login with phone number instead</IonLabel>
              </IonItem>
            </div>
            <div className="flex w-full flex-row justify-end">
              <IonButton
                className={"mt-8 h-9 w-20"}
                shape={"round"}
                color={"dark"}
                size={"small"}
                disabled={emailInputState.length === 0 || inProgress}
                onClick={(e) => {
                  e.preventDefault();
                  setInProgress(true);
                  login(emailInputState, passwordInputState)
                    .then((result) => {
                      if (result.status === 200) {
                        return saveLoginInfo(result.data, userContext);
                      } else {
                        return Promise.reject();
                      }
                    })
                    .then((savedUser) => {
                      if (savedUser.isProfileComplete) {
                        history.replace("/home");
                      } else {
                        history.replace("/complete-profile");
                      }
                    })
                    .catch((e) => handleError(e, userContext))
                    .finally(() => {
                      setInProgress(false);
                    });
                }}
              >
                Login
              </IonButton>
            </div>
          </main>
        </div>
      </div>
    </IonPage>
  );
}

function login(email: string, password: string): Promise<HttpResponse> {
  return POST({
    url: `${JIBI_BASE_URL}/collections/users/auth-with-password`,
    body: {
      identity: email,
      password: password,
    },
    params: {
      expand: "theme,matchPreference,location",
    },
  });
}

function saveLoginInfo(
  data: any,
  userContext: UserContextHook | undefined,
): Promise<SavedUser> {
  const {
    id,
    name,
    birthday,
    age,
    verified,
    avatar,
    email,
    username,
    gender,
    sexualOrientation,
    pushToken,
    expand,
  } = data.record;
  const user = {
    id: id,
    name: name,
    birthday: birthday === "" ? undefined : new Date(birthday),
    age: age,
    verified: verified,
    avatar: avatar,
    theme: expand.theme as Theme,
    email: email,
    username: username,
    gender: gender,
    sexualOrientation: sexualOrientation,
    matchPreference: expand.matchPreference as MatchPreferences,
    location: expand.location as Location,
    pushToken: pushToken,
  };
  return (
    userContext?.loginHook?.saveUser({
      token: data.token as string,
      user: user,
      isProfileComplete: isProfileComplete(user),
    }) || Promise.reject()
  );
}
