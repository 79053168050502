import React from "react";
import { IonButton, IonImg, IonPage, IonText } from "@ionic/react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Browser } from "@capacitor/browser";

export default function Login() {
  const history = useHistory();
  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          <header className="flex h-56 flex-row items-center justify-center">
            <IonImg
              src="/assets/betterdates.svg"
              alt="BetterDates Logo"
              className="h-10 w-full"
            />
          </header>
          <main className="flex h-full w-full flex-col justify-between p-4 pb-8">
            <div className="h-full w-full"></div>
            <div className="flex w-full flex-col gap-4">
              <IonButton
                className="h-15"
                shape={"round"}
                color={"dark"}
                onClick={() => {
                  history.replace("/phone-login");
                }}
              >
                Let&apos;s begin
              </IonButton>
              <IonText className="text-center text-xs">
                By continuing you&apos;re agreeing to our{" "}
                <Link
                  to={{ pathname: "https://betterdates.app/legal/terms" }}
                  target="_blank"
                  className="text-blue-400 underline"
                  onClick={(e) => {
                    e.preventDefault();
                    Browser.open({
                      url: "https://betterdates.app/legal/terms",
                    }).catch();
                  }}
                >
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link
                  to={{
                    pathname: "https://betterdates.app/legal/privacy-policy",
                  }}
                  className="text-blue-400 underline"
                  onClick={(e) => {
                    e.preventDefault();
                    Browser.open({
                      url: "https://betterdates.app/legal/privacy-policy",
                    }).catch();
                  }}
                >
                  Privacy Policy
                </Link>
              </IonText>
            </div>
          </main>
        </div>
      </div>
    </IonPage>
  );
}
