import { HttpResponse } from "@capacitor/core";
import { IonButton, IonInput } from "@ionic/react";
import { useContext, useState } from "react";
import { JIBI_BASE_URL, PATCH } from "../../../util/ApiClient";
import { UserContext } from "../../../util/BetterDatesApp";
import { handleError } from "../../../util/error";

export default function NameInformation(props: { onNext: () => void }) {
  const userContext = useContext(UserContext);
  if (!userContext?.userState?.loggedIn) {
    props.onNext();
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;
  const [nameInput, setNameInput] = useState("");
  const [inProgress, setInProgress] = useState(false);

  return (
    <div className="flex h-full w-full flex-col justify-between px-8 pb-4">
      <div className="flex w-full flex-col">
        <IonInput
          placeholder="Enter your name"
          type={"text"}
          autofocus={true}
          className="force-dark text-xl"
          value={nameInput}
          onIonInput={(e) => {
            const newInput = e.detail.value as string;
            setNameInput(newInput);
          }}
        />
      </div>
      <div className="flex w-full flex-row justify-end">
        <IonButton
          className={"mt-8 h-9 w-20"}
          shape={"round"}
          color={"dark"}
          disabled={nameInput.length === 0 || inProgress}
          size={"small"}
          onClick={(e) => {
            e.preventDefault();
            setInProgress(true);
            updateUsersName(nameInput, savedUser.token)
              .then((result) => {
                if (result.status !== 200) {
                  return Promise.reject(result);
                }
                const user = {
                  ...savedUser.user,
                  name: nameInput,
                };
                return userContext?.loginHook.saveUser({
                  ...savedUser,
                  user: user,
                });
              })
              .then(() => props.onNext())
              .catch((e) => handleError(e, userContext))
              .finally(() => {
                setInProgress(false);
              });
          }}
        >
          Next
        </IonButton>
      </div>
    </div>
  );
}

function updateUsersName(name: string, token: string): Promise<HttpResponse> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/user/name`,
    headers: {
      Authorization: token,
    },
    body: {
      name: name,
    },
  });
}
