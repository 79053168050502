import { HttpResponse } from "@capacitor/core";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import {
  IonButton,
  IonChip,
  IonContent,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonText,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import SexualOrientation from "../../../data/SexualOrientation";
import { GET, JIBI_BASE_URL, PATCH } from "../../../util/ApiClient";
import { UserContext } from "../../../util/BetterDatesApp";
import { handleError } from "../../../util/error";

export default function SexualOrientationPreferences() {
  const userContext = useContext(UserContext);
  const history = useHistory();
  if (!userContext?.userState?.loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;
  const [state, setState] = useState<SexualOrientationStateType>({
    loading: true,
  });
  const [sexualOrientation, setSexualOrientation] = useState<
    SexualOrientation | undefined
  >(undefined);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    fetchSexualOrientations(savedUser.token)
      .then((result) => {
        if (result.status !== 200) {
          return Promise.reject(result);
        }
        const sexualOrientations = result.data.items as SexualOrientation[];
        setState({ loading: false, sexualOrientations: sexualOrientations });
      })
      .catch((e) => handleError(e, userContext));
  }, []);

  useEffect(() => {
    if (state.loading) {
      return;
    }
    const sexualOrientations = state.sexualOrientations;
    const userSexualOrientationsId = savedUser.user.sexualOrientation;
    const usersSexualOrientations = sexualOrientations.find((value) => {
      return value.id === userSexualOrientationsId;
    });
    setSexualOrientation(usersSexualOrientations);
  }, [state]);

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          {state.loading ? (
            <>
              <div className="flex h-full w-full flex-col items-center justify-center">
                <IonText>Loading...</IonText>
              </div>
            </>
          ) : (
            <>
              <header className="flex flex-col gap-8 px-8 py-8">
                <div>
                  <IonChip
                    onClick={(e) => {
                      e.preventDefault();
                      history.goBack();
                    }}
                  >
                    <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
                    <IonLabel>Back</IonLabel>
                  </IonChip>
                </div>
                <div className="flex w-full flex-row items-start">
                  <IonText className="text-2xl font-bold">
                    Which best describes your sexual orientation?
                  </IonText>
                </div>
              </header>
              <main className="h-full w-full">
                <div className="flex h-full w-full flex-col justify-between px-8 pb-8">
                  <IonContent
                    className="scrollable text-gray-800"
                    scrollY={false}
                  >
                    <IonRadioGroup
                      value={sexualOrientation}
                      onIonChange={(e) => {
                        setSexualOrientation(e.detail.value);
                      }}
                    >
                      <div className="scrollable flex h-full w-full flex-col gap-10 overflow-y-scroll no-scrollbar">
                        {state.sexualOrientations.map((value) => (
                          <div className="scrollable" key={value.id}>
                            <IonRadio
                              value={value}
                              className="scrollable"
                              labelPlacement="end"
                              color="dark"
                            >
                              {value.name}
                            </IonRadio>
                          </div>
                        ))}
                      </div>
                    </IonRadioGroup>
                  </IonContent>
                  <div className="flex w-full flex-row justify-end">
                    <IonButton
                      className={"mt-8 h-9 w-20"}
                      shape={"round"}
                      color={"dark"}
                      disabled={sexualOrientation === undefined || inProgress}
                      size={"small"}
                      onClick={(e) => {
                        e.preventDefault();
                        setInProgress(true);
                        if (!sexualOrientation) return;
                        updateSexualOrientation(
                          sexualOrientation,
                          savedUser.token,
                        )
                          .then((result) => {
                            if (result.status !== 200) {
                              return Promise.reject(result);
                            }
                            const user = {
                              ...savedUser.user,
                              sexualOrientation: sexualOrientation.id,
                            };
                            return userContext?.loginHook.saveUser({
                              ...savedUser,
                              user: user,
                            });
                          })
                          .then(() => history.goBack())
                          .catch((e) => handleError(e, userContext))
                          .finally(() => {
                            setInProgress(false);
                          });
                      }}
                    >
                      Save
                    </IonButton>
                  </div>
                </div>
              </main>
            </>
          )}
        </div>
      </div>
    </IonPage>
  );
}

interface SexualOrientationState {
  loading: boolean;
}

interface SexualOrientationStateLoaded extends SexualOrientationState {
  loading: false;
  sexualOrientations: SexualOrientation[];
}

interface SexualOrientationStateLoading extends SexualOrientationState {
  loading: true;
}

type SexualOrientationStateType =
  | SexualOrientationStateLoading
  | SexualOrientationStateLoaded;

function fetchSexualOrientations(token: string): Promise<HttpResponse> {
  return GET({
    url: `${JIBI_BASE_URL}/collections/sexual_orientations_view/records`,
    headers: {
      Authorization: token,
    },
  });
}

function updateSexualOrientation(
  sexualOrientation: SexualOrientation,
  token: string,
): Promise<HttpResponse> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/user/sexual_orientation`,
    headers: {
      Authorization: token,
    },
    body: {
      sexualOrientation: sexualOrientation.id,
    },
  });
}
