export default function RegistrationStepper({
  steps,
  completedUntil,
}: RegistrationStepperProps) {
  return (
    <ol className="flex w-full flex-row items-center justify-center">
      {[...Array(steps)].map((_, index) => (
        <Step
          key={index}
          step={index + 1}
          isLastStep={index + 1 === steps}
          isCompleted={completedUntil > index}
          isActiveStep={index === completedUntil}
        />
      ))}
    </ol>
  );
}

function Step({ step, isLastStep, isCompleted, isActiveStep }: StepProps) {
  let parentStyle: string = "flex items-center";
  if (!isLastStep) {
    parentStyle = `${parentStyle} w-full after:inline-block after:h-1 after:w-full after:border-4 after:border-b after:content-['']`;
  }
  let childStyle: string =
    "flex h-5 w-5 shrink-0 items-center justify-center rounded-full lg:h-6 lg:w-6 text-sm";
  if (isCompleted) {
    parentStyle = `${parentStyle} after:border-black`;
    childStyle = `${childStyle} bg-black text-white`;
  } else if (isActiveStep) {
    childStyle = `${childStyle} bg-gray-300 text-gray-700`;
  } else {
    parentStyle = `${parentStyle} after:border-gray-300`;
    childStyle = `${childStyle} bg-gray-200 text-gray-500`;
  }
  return (
    <li className={parentStyle}>
      <div className={childStyle}>{step}</div>
    </li>
  );
}

type StepProps = {
  step: number;
  isLastStep: boolean;
  isCompleted: boolean;
  isActiveStep: boolean;
};

type RegistrationStepperProps = {
  steps: number;
  completedUntil: number;
};
