import { dataUrlToFile } from "./FileExt";
import Compressor from "compressorjs";

export function getAvatarFile(
  userId: string,
  avatarPath: string,
): Promise<File> {
  const fileName = `avatar_${userId}_${new Date().getMilliseconds()}`;
  return dataUrlToFile(avatarPath, fileName);
}

export function compressAvatar(avatarFile: File): Promise<File | Blob> {
  return new Promise((resolve, reject) => {
    new Compressor(avatarFile, {
      quality: 0.8,
      convertSize: 3000000,
      maxWidth: 1080,
      convertTypes: ["image/png", "image/jpeg"],
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err);
      },
    });
  });
}
