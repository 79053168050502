import { HttpResponse } from "@capacitor/core";
import { IonButton, IonRadio, IonRadioGroup, IonTextarea } from "@ionic/react";
import React, { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Match, MatchMeta, MatchReport } from "../../data/Match";
import { ERROR_DEFAULT } from "../../i18n/static";
import { isHttpResponse, JIBI_BASE_URL, POST } from "../../util/ApiClient";
import { UserContext } from "../../util/BetterDatesApp";
import { defaultToastState } from "../../util/IonicExt";
import { handleError, responseHasClientError } from "../../util/error";
import { ModalStateType } from "./Modal";

export default function ReportModal({
  setModalState,
  matchId,
}: ReportModalProps) {
  const userContext = useContext(UserContext);
  const history = useHistory();
  const [selectedReason, setSelectedReason] = useState<string>("");
  const [otherText, setOtherText] = useState<string>("");
  const [inProgress, setInProgress] = useState(false);
  const match = useMemo(() => {
    return userContext?.cache.matches?.find((value) => {
      return value.id === matchId;
    });
  }, [userContext]);

  if (!userContext?.userState.loggedIn || !match) {
    return <></>;
  }

  const handleRadioChange = (value: string) => {
    setSelectedReason(value);
    if (value !== "Others") {
      setOtherText("");
    }
  };

  const savedUser = userContext.userState.savedUser;

  return (
    <main className="flex h-full w-full flex-col justify-between px-4 py-6">
      <div className="flex h-full w-full flex-col pb-4">
        <IonRadioGroup
          value={selectedReason}
          onIonChange={(e) => handleRadioChange(e.detail.value)}
        >
          <div className="scrollable scrollable flex h-full w-full flex-col gap-8 overflow-y-scroll no-scrollbar">
            <div className="flex items-center">
              <IonRadio value="Spam" color="dark" labelPlacement="end">
                Spam
              </IonRadio>
            </div>
            <div className="flex items-center">
              <IonRadio value="Inappropriate" color="dark" labelPlacement="end">
                Inappropriate content
              </IonRadio>
            </div>
            <div className="flex items-center">
              <IonRadio value="Harassment" color="dark" labelPlacement="end">
                Harassment
              </IonRadio>
            </div>
            <div className="flex items-center">
              <IonRadio value="Others" color="dark" labelPlacement="end">
                Other
              </IonRadio>
            </div>
          </div>
        </IonRadioGroup>
        {selectedReason === "Others" && (
          <IonTextarea
            ref={(ref) => {
              if (ref) {
                ref.getInputElement().then((element) => {
                  element.dir = "auto";
                });
              }
            }}
            autofocus={true}
            color={"dark"}
            className="force-dark mt-4 justify-center overflow-y-scroll rounded border border-black px-4 no-scrollbar"
            placeholder="Please specify"
            value={otherText}
            onIonInput={(e) => setOtherText(e.detail.value!)}
          />
        )}
      </div>
      <div className="flex w-full flex-row justify-end">
        <IonButton
          className={"mt-8 h-9 w-20"}
          shape={"round"}
          color={"dark"}
          size={"small"}
          disabled={selectedReason.length === 0 || inProgress}
          onClick={(e) => {
            e.preventDefault();
            setInProgress(true);

            const reporter = savedUser?.user.id;
            const reported = matchId;
            const token = savedUser?.token;
            const reason = selectedReason;
            const description = otherText;

            sendReport(reporter, reported, matchId, reason, description, token)
              .then((response) => {
                if (response.status !== 201) {
                  return Promise.reject(response);
                }

                const reported = response.data.reported as MatchReport;
                const updatedMeta: MatchMeta = {
                  ...match.meta,
                  reported: reported,
                };
                const updatedMatch: Match = {
                  ...match,
                  meta: updatedMeta,
                };
                const oldMatches =
                  userContext?.cache.matches?.filter((value) => {
                    return value.id !== matchId;
                  }) || [];
                userContext?.cacheHook.cacheMatches([
                  ...oldMatches,
                  updatedMatch,
                ]);
                setModalState({ isVisible: false });
                history.replace("/");
              })
              .catch((error) => handleError(error, userContext))
              .finally(() => {
                setInProgress(false);
              });
          }}
        >
          Submit
        </IonButton>
      </div>
    </main>
  );
}

function sendReport(
  reporter: string,
  reported: string,
  matchId: string,
  reason: string,
  description: string,
  token: string,
): Promise<HttpResponse> {
  return POST({
    url: `${JIBI_BASE_URL}/v1/match/${matchId}/report`,
    headers: {
      Authorization: token,
    },
    body: {
      reporter: reporter,
      reported: reported,
      reason: reason,
      description: description,
    },
  });
}

interface ReportModalProps {
  setModalState: React.Dispatch<React.SetStateAction<ModalStateType>>;
  matchId: string;
}
