export default interface ErrorHook {
  logError: (level: LogLevel, error: Error) => void;
}

export type ErrorHookInternals = {
  logError: (level: LogLevel, error: Error) => void;
};

export function useErrorHook(
  errorHookInternals: ErrorHookInternals,
): ErrorHook {
  return {
    logError: (level, error) => {
      errorHookInternals.logError(level, error);
    },
  };
}

export type LogLevel = "I" | "E";
