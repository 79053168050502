import { SavedUser } from "../hooks/LoginHook";
import { JIBI_BASE_URL, PATCH, POST } from "../util/ApiClient";
import { HttpResponse } from "@capacitor/core";

export function patchUserAvatar(
  savedUser: SavedUser,
  avatar: File | Blob,
): Promise<Response> {
  const formData = new FormData();
  let fileName = `avatar_${savedUser.user.id}_${new Date().getMilliseconds()}`;
  if (avatar instanceof File) {
    fileName = avatar.name;
  }
  formData.set("avatar", avatar, fileName);
  return fetch(`${JIBI_BASE_URL}/v1/user/avatar`, {
    method: "PATCH",
    headers: {
      Authorization: savedUser.token,
    },
    body: formData,
  });
}

export function patchUserPush(
  savedUser: SavedUser,
  pushToken: string,
): Promise<HttpResponse> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/user/push`,
    headers: {
      Authorization: savedUser.token,
    },
    body: {
      pushToken: pushToken,
    },
  });
}

export function postUserEmailVerification(
  savedUser: SavedUser,
  email: string,
): Promise<HttpResponse> {
  return POST({
    url: `${JIBI_BASE_URL}/v1/user/verification/email`,
    headers: {
      Authorization: savedUser.token,
    },
    body: {
      email: email,
    },
  });
}

export function patchUserEmailVerification(
  savedUser: SavedUser,
  email: string,
  code: string,
): Promise<HttpResponse> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/user/verification/email`,
    headers: {
      Authorization: savedUser.token,
    },
    body: {
      email: email,
      code: code,
    },
  });
}
