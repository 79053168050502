import React, { useState } from "react";
import { IonImg } from "@ionic/react";

interface AvatarProps {
  src: string;
  className?: string;
  alt: string;
  onLoad?: () => void;
  onError?: () => void;
}

const Avatar: React.FC<AvatarProps> = ({
  src,
  className,
  alt,
  onLoad,
  onError,
}) => {
  const [error, setError] = useState(false);

  const handleError = () => {
    setError(true);
    if (onError) {
      onError();
    }
  };

  return (
    <>
      {error ? (
        <div className={`h-full w-full bg-gray-400 ${className}`} />
      ) : (
        <IonImg
          src={src}
          draggable={false}
          className={className}
          alt={alt}
          onIonImgDidLoad={() => {
            if (onLoad) {
              onLoad();
            }
          }}
          onIonError={handleError}
        />
      )}
    </>
  );
};

export default Avatar;
