export default interface ServiceWorkerHook {
  registration: () => ServiceWorkerRegistration | undefined;
}

export type ServiceWorkerHookInternals = {
  registration: () => ServiceWorkerRegistration | undefined;
};

export function useServiceWorkerHook(
  serviceWorkerHookInternals: ServiceWorkerHookInternals,
): ServiceWorkerHook {
  return {
    registration: () => {
      return serviceWorkerHookInternals.registration();
    },
  };
}
