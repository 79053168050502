import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import { Capacitor, HttpResponse } from "@capacitor/core";
import { Keyboard, KeyboardResize } from "@capacitor/keyboard";
import { Preferences } from "@capacitor/preferences";
import { IonApp, IonToast, setupIonicReact } from "@ionic/react";
import * as Sentry from "@sentry/react";
import { getApps, initializeApp } from "firebase/app";
import { KonstaProvider } from "konsta/react";
import { AppMetaData, Native } from "nativebridge";
import { NativeWeb } from "nativebridge/dist/esm/web";
import { isTokenExpired } from "pocketbase";
import { createContext, useEffect, useState } from "react";
import AppRouter from "../AppRouter";
import { AppsVersionInfo, AppVersion, defaultConfig } from "../data/Config";
import { emptyPreferencesData } from "../data/PreferencesData";
import { BIRTHDAY_FIELD } from "../data/User";
import { getInstantConfig } from "../datasource/instantconfig";
import { InMemoryCache, useCacheHook } from "../hooks/CacheHook";
import { useErrorHook } from "../hooks/error-hook";
import { SavedUser, useLoginHook, USER_PREFERENCES } from "../hooks/LoginHook";
import { useServiceWorkerHook } from "../hooks/serviceworker-hook";
import { useToastHook } from "../hooks/ToastHook";
import UserContextHook, { AccountState } from "../hooks/UserContextHook";
import ForceUpdate from "../pages/emptystate/ForceUpdate";
import MaintenanceMode from "../pages/emptystate/MaintenanceMode";
import { JIBI_BASE_URL, POST } from "./ApiClient";
import { CAUSE_INFORMATIVE, informativeError } from "./error";
import { defaultToastState, ToastState } from "./IonicExt";

setupIonicReact();

Keyboard.setResizeMode({ mode: KeyboardResize.Ionic }).catch(() => {
  // Ignored
});

// Request for refresh token, seconds(604800) before it actually expires.
const TOKEN_EXPIRY_THRESHOLD: number = 604800;

export default function BetterDatesApp() {
  const [accountState, setAccountState] = useState<AccountState>("loading");
  const [appElevator, setAppElevator] = useState<AppElevator>("ground_floor");
  const [cacheState, setCacheState] = useState<InMemoryCache>({
    matches: undefined,
    preferencesData: emptyPreferencesData(),
    letters: new Map(),
    appConfig: defaultConfig(),
  });
  const [toastState, setToastState] = useState<ToastState>(defaultToastState());
  const [serviceWorkerRegistration, setServiceWorkerRegistration] = useState<
    ServiceWorkerRegistration | undefined
  >();

  const loginHook = useLoginHook({
    setCurrentUser: (state) => {
      setAccountState((prevState) => {
        if (prevState !== "loading" && prevState.loggedIn !== state.loggedIn) {
          location.reload();
        }
        return state;
      });
    },
    logoutUser: () => {},
  });

  const authRefresh = (token: string) => {
    requestAuthRefresh(token)
      .then((result) => {
        if (result.status !== 200) {
          return loginHook.logoutUser();
        }
        const newToken = result.data.token;
        retrieveSavedUser().then((savedUser) => {
          return loginHook.saveUser({
            ...savedUser,
            token: newToken,
          });
        });
      })
      .catch((e) => {
        errorHook.logError("E", e);
        loginHook.logoutUser().catch();
      });
  };

  const getAppMetaData = async () => {
    const nativeAppMetaData = await Native.getAppMetaData();
    const webAppMetaData = await new NativeWeb().getAppMetaData();
    return {
      nativeAppMetaData,
      webAppMetaData,
    };
  };

  useEffect(() => {
    if (appElevator !== "ground_floor") {
      return;
    }
    retrieveSavedUser()
      .then((savedUser) => {
        if (isTokenExpired(savedUser.token, TOKEN_EXPIRY_THRESHOLD)) {
          authRefresh(savedUser.token);
        } else {
          setAccountState({
            loggedIn: true,
            savedUser: savedUser,
          });
        }
      })
      .catch((e) => {
        errorHook.logError("E", e);
        setAccountState({ loggedIn: false });
      })
      .finally(() => {
        setAppElevator("first_floor");
      });
  }, []);

  useEffect(() => {
    if (appElevator !== "first_floor") {
      return;
    }
    let newAppConfig = { ...cacheState.appConfig };
    getInstantConfig()
      .then((result) => {
        if (result.status !== 200) {
          return Promise.reject(result);
        }
        const response = JSON.parse(result.data);
        newAppConfig = {
          ...newAppConfig,
          ...response,
        };
      })
      .then(() => getAppMetaData())
      .then((appMetaData) => {
        newAppConfig = {
          ...newAppConfig,
          forceUpdate: isForceUpdateRequired(
            appMetaData.nativeAppMetaData,
            appMetaData.webAppMetaData,
            newAppConfig.deprecatedVersion,
          ),
          currentWebVersion: appMetaData.webAppMetaData,
          currentNativeVersion: appMetaData.nativeAppMetaData,
        };
      })
      .catch((e) => errorHook.logError("E", e))
      .finally(() => {
        setCacheState((prevState) => {
          return {
            ...prevState,
            appConfig: newAppConfig,
          };
        });
        setAppElevator("second_floor");
      });
  }, [appElevator]);

  useEffect(() => {
    if (appElevator !== "second_floor") {
      return;
    }
    initFirebase();
    FirebaseAnalytics.setEnabled({ enabled: true }).catch((e) =>
      errorHook.logError("E", e),
    );
    setupServiceWorker()
      .then((registration) => {
        setServiceWorkerRegistration(registration);
      })
      .catch((e) => {
        errorHook.logError("E", e);
      });
  }, [appElevator]);

  const cacheHook = useCacheHook({
    cacheMatches: (matches) => {
      setCacheState((prevState) => {
        return {
          ...prevState,
          matches: matches,
        };
      });
    },
    updatePreferencesData: (preferencesData) => {
      setCacheState((prevState) => {
        return {
          ...prevState,
          preferencesData: preferencesData,
        };
      });
    },
    cacheLetters: (matchId, letters) => {
      setCacheState((prevState) => {
        return {
          ...prevState,
          letters: prevState.letters.set(matchId, letters),
        };
      });
    },
  });

  const toastHook = useToastHook({
    toast: (toastOptions) => {
      setToastState(toastOptions);
    },
  });

  const serviceWorkerHook = useServiceWorkerHook({
    registration: () => {
      return serviceWorkerRegistration;
    },
  });

  const errorHook = useErrorHook({
    logError: (level, error) => {
      if (import.meta.env.DEV) {
        console.log(error);
      }
      if (
        import.meta.env.PROD &&
        level === "E" &&
        error.cause != CAUSE_INFORMATIVE
      ) {
        Sentry.captureException(error);
      }
    },
  });

  return (
    <>
      {appElevator === "second_floor" && accountState !== "loading" && (
        <UserContext.Provider
          value={{
            userState: accountState,
            loginHook: loginHook,
            cache: cacheState,
            cacheHook: cacheHook,
            toastHook: toastHook,
            serviceWorkerHook: serviceWorkerHook,
            errorHook: errorHook,
          }}
        >
          <KonstaProvider theme="parent" dark={false}>
            <IonApp>
              {cacheState.appConfig.forceUpdate ? (
                <ForceUpdate />
              ) : cacheState.appConfig.maintenanceMode ? (
                <MaintenanceMode />
              ) : (
                <AppRouter />
              )}
            </IonApp>
          </KonstaProvider>
          <IonToast
            message={toastState.message}
            isOpen={toastState.isOpen}
            duration={toastState.duration}
            position={toastState.position}
            onDidDismiss={() => {
              setToastState({ ...toastState, isOpen: false });
            }}
          />
        </UserContext.Provider>
      )}
    </>
  );
}

async function retrieveSavedUser(): Promise<SavedUser> {
  const result = await Preferences.get({ key: USER_PREFERENCES });
  if (result.value === null) {
    return Promise.reject(informativeError("User needs to login"));
  }
  return JSON.parse(result.value, (key, value) => {
    if (key === BIRTHDAY_FIELD) {
      return new Date(value);
    }
    return value;
  }) as SavedUser;
}

function requestAuthRefresh(token: string): Promise<HttpResponse> {
  return POST({
    url: `${JIBI_BASE_URL}/v1/user/auth-refresh`,
    headers: {
      Authorization: token,
    },
  });
}

export const UserContext = createContext<UserContextHook | undefined>(
  undefined,
);

function initFirebase() {
  if (Capacitor.getPlatform() !== "web" || getApps().length > 0) return;
  initializeApp({
    apiKey: import.meta.env.VITE_FIREBASE_APIKEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APPID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  });
}

function setupServiceWorker(): Promise<ServiceWorkerRegistration> {
  if (Capacitor.getPlatform() === "web" && "serviceWorker" in navigator) {
    return navigator.serviceWorker.register(
      import.meta.env.MODE === "production" ? "/sw.js" : "/dev-sw.js?dev-sw",
      { type: import.meta.env.MODE === "production" ? "classic" : "module" },
    );
  }
  return Promise.reject(informativeError("Service worker not supported"));
}

function isForceUpdateRequired(
  nativeAppMetaData: AppMetaData,
  webAppMetaData: AppMetaData,
  deprecatedVersion?: AppsVersionInfo,
): boolean {
  const platform = Capacitor.getPlatform();
  let forceUpdate = false;
  if (isInNeedOfUpdate(webAppMetaData, deprecatedVersion?.frontend)) {
    return true;
  }
  switch (platform) {
    case "ios":
      if (isInNeedOfUpdate(nativeAppMetaData, deprecatedVersion?.iOS)) {
        forceUpdate = true;
      }
      break;
    case "android":
      if (isInNeedOfUpdate(nativeAppMetaData, deprecatedVersion?.android)) {
        forceUpdate = true;
      }
      break;
  }
  return forceUpdate;
}

function isInNeedOfUpdate(
  appMetaData: AppMetaData,
  deprecatedVersion?: AppVersion,
): boolean {
  return (
    appMetaData.version.versionCode <= (deprecatedVersion?.versionCode || 0)
  );
}

type AppElevator = "ground_floor" | "first_floor" | "second_floor";
