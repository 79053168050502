import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { patchUserPush } from "../datasource/user-datasource";
import UserContextHook from "../hooks/UserContextHook";

export function requestNotificationPermission(
  userContextHook: UserContextHook,
) {
  const getToken = async () => {
    if (!userContextHook.userState.loggedIn) {
      return;
    }
    const tokenResult = await FirebaseMessaging.getToken({
      vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
      serviceWorkerRegistration:
        userContextHook.serviceWorkerHook.registration(),
    });
    if (
      !tokenResult.token ||
      userContextHook.userState.savedUser.user.pushToken === tokenResult.token
    ) {
      return;
    }
    const response = await patchUserPush(
      userContextHook.userState.savedUser,
      tokenResult.token,
    );
    if (response.status !== 200) {
      return;
    }
    const pushToken = response.data.pushToken as string;
    const newUser = {
      ...userContextHook.userState.savedUser.user,
      pushToken: pushToken,
    };
    await userContextHook.loginHook.saveUser({
      ...userContextHook.userState.savedUser,
      user: newUser,
    });
  };
  const requestPermissions = async () => {
    if (!(await FirebaseMessaging.isSupported())) {
      return;
    }
    const checkPermission = await FirebaseMessaging.checkPermissions();
    if (
      checkPermission.receive === "granted" ||
      (await FirebaseMessaging.requestPermissions()).receive === "granted"
    ) {
      await getToken();
    }
  };
  requestPermissions().catch((e) => userContextHook.errorHook.logError("E", e));
}
