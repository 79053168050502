import { IonButton, IonText } from "@ionic/react";
import React from "react";
import { ModalStateType } from "./Modal";

export default function PreferenceExampleModal({
  setModalState,
}: PreferenceExampleModalProps) {
  return (
    <main className="flex h-full w-full flex-col justify-between px-4">
      <div className="flex h-full w-full flex-col gap-4">
        <IonText className="pt-4">
          <span className="text-lg font-bold">
            Your description is too short!
          </span>{" "}
          <br />
          <span>
            Please try to provide as much information as possible in what
            exactly you&apos;re looking for. This helps us to find your perfect
            match. <br /> <br /> For example, you can include things like:
            <br />
            <br />• If you&apos;re looking for a committed relationship or a
            casual one. <br />
            • If being religious is important to you, or if you have a certain
            diet and like your match to have that diet too!
            <br />• If you have a certain hobby and would like your match to
            have them too, like video games, Harry Potter, Legos, etc. <br />
            • If you like your match to have certain type of personality, being
            outgoing, or more reserved and shy!
            <br />
            <br /> In short, imagine{" "}
            <b>1. what type of relationship you&apos;re looking for</b> and{" "}
            <b>2. how the person you&apos;d like to date might be like,</b> and describe it to us.
            
          </span>
        </IonText>
        <div className="flex w-full flex-row justify-end pb-4">
          <IonButton
            className={"mt-8 h-9 w-20"}
            shape={"round"}
            color={"dark"}
            size={"small"}
            onClick={() => {
              setModalState({
                isVisible: false,
              });
            }}
          >
            OK
          </IonButton>
        </div>
      </div>
    </main>
  );
}

interface PreferenceExampleModalProps {
  setModalState: React.Dispatch<React.SetStateAction<ModalStateType>>;
}
