import {
  IonButton,
  IonButtons,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./Modal.css";
import React from "react";

export default function Modal({
  isOpen,
  onDismiss,
  children,
  title,
  dismiss,
}: ModalProps) {
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss} className="custom-modal">
      <IonHeader>
        <IonToolbar>
          <IonTitle className="text-base font-bold">{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}>{dismiss || "Close"}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <div className="modal-content">{children}</div>
    </IonModal>
  );
}

interface ModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  children: React.ReactNode;
  title: string;
  dismiss?: string;
}

interface ModalState {
  isVisible: boolean;
}

interface Visible extends ModalState {
  isVisible: true;
  content: JSX.Element;
  title: string;
  dismiss?: string;
}

interface Hidden extends ModalState {
  isVisible: false;
}

export type ModalStateType = Visible | Hidden;
