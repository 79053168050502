import { HttpResponse } from "@capacitor/core";
import {
  CheckCircleIcon,
  LockClosedIcon,
  LockOpenIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { IonAlert, IonAvatar, IonRippleEffect, IonText } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { Match, MatchMeta } from "../data/Match";
import { JIBI_BASE_URL, PATCH } from "../util/ApiClient";
import { UserContext } from "../util/BetterDatesApp";
import { handleError } from "../util/error";
import Avatar from "./Avatar";

export default function MatchState({
  match,
  updateMatch,
  dateFormatter,
}: MatchStateProps) {
  const userContext = useContext(UserContext);
  if (!userContext?.userState.loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;

  const changeMatchState = (locked: boolean) => {
    patchMatchMeta(locked, match.id, savedUser.token)
      .then((result) => {
        if (result.status !== 200) {
          return Promise.reject(result);
        }
        const updatedMeta = result.data.meta as MatchMeta;
        const updatedMatch = {
          ...match,
          meta: updatedMeta,
        };
        const oldMatches =
          userContext?.cache.matches?.filter((value) => {
            return value.id !== match.id;
          }) || [];
        userContext?.cacheHook.cacheMatches([...oldMatches, updatedMatch]);
        updateMatch(updatedMatch);
      })
      .catch((e) => handleError(e, userContext));
  };

  return (
    <div className="mt-3 flex w-full flex-row gap-2 px-3">
      {!match.meta.locked && match.meta.requestedLock === match.partyTwo.id ? (
        <>
          <div className="flex h-full w-full flex-col justify-center">
            <IonText className="text-sm">{`${match.partyTwo.name} has requested to lock the match.`}</IonText>
            <IonText className="text-sm text-gray-500">
              Accept to avoid your match being expired.
            </IonText>
          </div>
          <div className="flex h-full flex-row items-center justify-center gap-3">
            <div
              className="ion-activatable relative flex h-10 w-10 items-center justify-center overflow-hidden rounded-full"
              onClick={(e) => {
                e.preventDefault();
                changeMatchState(true);
              }}
            >
              <CheckCircleIcon className="h-8 w-8 stroke-emerald-500" />
              <IonRippleEffect />
            </div>
            <div
              className="ion-activatable relative flex h-10 w-10 items-center justify-center overflow-hidden rounded-full"
              onClick={(e) => {
                e.preventDefault();
                changeMatchState(false);
              }}
            >
              <XCircleIcon className="h-8 w-8 stroke-gray-500" />
              <IonRippleEffect />
            </div>
          </div>
        </>
      ) : (
        <>
          <MatchLockButton match={match} changeMatchState={changeMatchState} />
          <div className="flex h-full w-full flex-col justify-center">
            {!match.meta.locked && match.meta.requestedLock === "" && (
              <>
                <IonText className="text-sm">{`Your match expires in ${dateFormatter.format(
                  new Date(match.meta.expiresAt),
                )}`}</IonText>
                <IonText className="text-sm text-gray-500">
                  Click on your avatars to lock and stay matched.
                </IonText>
              </>
            )}
            {!match.meta.locked &&
              match.meta.requestedLock === savedUser.user.id && (
                <>
                  <IonText className="text-sm">{`Waiting for ${match.partyTwo.name}'s consent to lock the match.`}</IonText>
                  <IonText className="text-sm text-gray-500">{`Your match expires in ${dateFormatter.format(
                    new Date(match.meta.expiresAt),
                  )}`}</IonText>
                </>
              )}
            {match.meta.locked && (
              <IonText className="text-sm">
                Your match is locked and will not expire.
              </IonText>
            )}
          </div>
        </>
      )}
    </div>
  );
}

function MatchLockButton({ match, changeMatchState }: MatchLockButtonProps) {
  const userContext = useContext(UserContext);
  const [isLockMatchConfirmationOpen, setIsLockMatchConfirmationOpen] =
    useState(false);
  const [isUnlockMatchConfirmationOpen, setIsUnlockMatchConfirmationOpen] =
    useState(false);
  const [isWaitingForConsentOpen, setIsWaitingForConsentOpen] = useState(false);
  const [avatarLoad, setAvatarLoad] = useState<string[]>([]);
  const matchLockBackDrop = useRef<HTMLDivElement>();
  if (!userContext?.userState.loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;

  useEffect(() => {
    if (
      !avatarLoad.includes("partyOne") ||
      !avatarLoad.includes("partyTwo") ||
      matchLockBackDrop.current === undefined
    ) {
      return;
    }
    matchLockBackDrop.current.className = `${matchLockBackDrop.current.className} match-lock-blur`;
  }, [avatarLoad, matchLockBackDrop]);

  return (
    <>
      <div className="relative">
        <div
          className="ion-activatable relative overflow-hidden rounded-xl p-2"
          onClick={(e) => {
            e.preventDefault();
            if (match.meta.locked) {
              setIsUnlockMatchConfirmationOpen(true);
            } else if (match.meta.requestedLock === savedUser.user.id) {
              setIsWaitingForConsentOpen(true);
            } else {
              setIsLockMatchConfirmationOpen(true);
            }
          }}
        >
          <div className="absolute z-20 flex h-12 w-full items-center justify-center">
            <div
              className="flex h-7 w-7 items-center justify-center overflow-clip rounded-full"
              ref={(ref) => {
                if (ref) {
                  matchLockBackDrop.current = ref;
                }
              }}
            >
              {match.meta.locked ? (
                <LockClosedIcon className="h-5 w-5 stroke-white" />
              ) : (
                <LockOpenIcon className="h-5 w-5 stroke-white" />
              )}
            </div>
          </div>
          <div className="flex -space-x-4">
            <IonAvatar className="z-10 h-12 w-12 ring-2 ring-gray-50">
              <Avatar
                src={`${JIBI_BASE_URL}/files/_pb_users_auth_/${match.partyTwo.id}/${match.partyTwo.avatar}`}
                alt={""}
                onLoad={() => {
                  setAvatarLoad((prevState) => {
                    return [...prevState, "partyTwo"];
                  });
                }}
              />
            </IonAvatar>
            <IonAvatar className="z-0 h-12 w-12">
              <Avatar
                src={`${JIBI_BASE_URL}/files/_pb_users_auth_/${savedUser.user.id}/${savedUser.user.avatar}`}
                alt={""}
                onLoad={() => {
                  setAvatarLoad((prevState) => {
                    return [...prevState, "partyOne"];
                  });
                }}
              />
            </IonAvatar>
          </div>
          <IonRippleEffect />
        </div>
      </div>
      <IonAlert
        isOpen={isLockMatchConfirmationOpen}
        id="lockMatchAlert"
        header="Lock match?"
        message={`We'll notify ${match.partyTwo.name} to get their consent for match lock.`}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "Lock",
            handler: () => {
              changeMatchState(true);
            },
          },
        ]}
        onDidDismiss={() => {
          setIsLockMatchConfirmationOpen(false);
        }}
      />
      <IonAlert
        isOpen={isUnlockMatchConfirmationOpen}
        id="unlockMatchAlert"
        header="Unlock match?"
        message={`Your match will expire after some time.`}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "Unlock",
            role: "destructive",
            handler: () => {
              changeMatchState(false);
            },
          },
        ]}
        onDidDismiss={() => {
          setIsUnlockMatchConfirmationOpen(false);
        }}
      />
      <IonAlert
        isOpen={isWaitingForConsentOpen}
        id="waitingForConsentAlert"
        message={`You have already requested to lock the match. Please wait for ${match.partyTwo.name}'s consent to lock the match.`}
        buttons={[
          {
            text: "OK",
          },
        ]}
        onDidDismiss={() => {
          setIsWaitingForConsentOpen(false);
        }}
      />
    </>
  );
}

function patchMatchMeta(
  locked: boolean,
  matchId: string,
  token: string,
): Promise<HttpResponse> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/match_meta/${matchId}`,
    headers: {
      Authorization: token,
    },
    body: {
      locked: locked,
    },
  });
}

interface MatchLockButtonProps {
  match: Match;
  changeMatchState: (locked: boolean) => void;
}

interface MatchStateProps {
  match: Match;
  updateMatch: (updatedMatch: Match) => void;
  dateFormatter: Intl.DateTimeFormat;
}
