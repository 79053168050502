import { Location } from "./location";
import { MatchPreferences } from "./match-preferences";
import Theme from "./Theme";

export const BIRTHDAY_FIELD = "birthday";

export default interface User {
  id: string;
  email: string | undefined;
  name: string | undefined;
  [BIRTHDAY_FIELD]: Date | undefined;
  avatar: string | undefined;
  gender: string | undefined;
  sexualOrientation: string | undefined;
  location: Location | undefined;
  matchPreference: MatchPreferences | undefined;
  theme: Theme;
  pushToken: string | undefined;
}

export enum ApplicationStatus {
  InReview = "in_review",
  Approved = "approved",
  Rejected = "rejected",
}
