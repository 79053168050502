import { useContext, useState } from "react";
import { UserContext } from "../../util/BetterDatesApp";
import Modal, { ModalStateType } from "../../components/modal/Modal";
import PreferenceExampleModal from "../../components/modal/PreferenceExampleModal";
import {
  IonButton,
  IonChip,
  IonContent,
  IonLabel,
  IonPage,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { useHistory } from "react-router";
import { HttpResponse } from "@capacitor/core";
import { JIBI_BASE_URL, PATCH } from "../../util/ApiClient";
import { handleError } from "../../util/error";

export default function DescriptionPreferences() {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const loggedIn = userContext?.userState.loggedIn;
  if (!loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;
  const [description, setDescription] = useState(
    userContext.userState.savedUser.user.matchPreference?.description || "",
  );
  const [modalState, setModalState] = useState<ModalStateType>({
    isVisible: false,
  });

  const showPreferenceExampleModal = () => {
    setModalState({
      isVisible: true,
      content: <PreferenceExampleModal setModalState={setModalState} />,
      title: "",
    });
  };
  const handleModalDismiss = () => {
    setModalState({
      isVisible: false,
    });
  };

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="safe-container flex h-full w-full max-w-md flex-col justify-between">
        <header className="flex flex-row items-center px-8 py-8">
          <IonChip
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
            <IonLabel>Back</IonLabel>
          </IonChip>
        </header>
        <main className="safe-scroller flex h-full w-full flex-col justify-between">
          <div className="flex flex-col items-center gap-8 px-8">
            <IonText className="text-2xl font-bold">
              What and who are you looking for?
            </IonText>
          </div>
          <div className="h-full w-full py-8">
            <div className="flex h-full w-full flex-col justify-between px-8">
              <IonContent className="scrollable text-gray-800" scrollY={false}>
                <IonText>
                  Describe in couple of sentences what and who you&apos;re
                  looking for. We use this information to match you with the
                  right person.
                </IonText>
                <IonTextarea
                  className="mt-8 w-full align-text-top"
                  placeholder="I'm looking for..."
                  value={description}
                  counter={true}
                  spellcheck={true}
                  maxlength={512}
                  autoGrow={true}
                  onIonInput={(e) => {
                    const newInput = e.detail.value as string;
                    setDescription(newInput);
                  }}
                />
              </IonContent>
              <div className="flex w-full flex-row justify-end">
                <IonButton
                  className={"mt-8 h-9 w-20"}
                  shape={"round"}
                  color={"dark"}
                  disabled={description.length === 0}
                  size={"small"}
                  onClick={(e) => {
                    e.preventDefault();
                    const trimmedDescription = description
                      .replace(/\s{2,}/g, " ")
                      .trim();
                    if (trimmedDescription.length < 96) {
                      showPreferenceExampleModal();
                      return;
                    }
                    patchDescriptionPreferences(
                      trimmedDescription,
                      savedUser.token,
                    )
                      .then((result) => {
                        if (result.status !== 200) {
                          return Promise.reject(result);
                        }
                        const newMatchPreferences = {
                          ...savedUser.user.matchPreference,
                          ...result.data.preferences,
                        };
                        const user = {
                          ...savedUser.user,
                          matchPreference: newMatchPreferences,
                        };
                        return userContext?.loginHook?.saveUser({
                          ...savedUser,
                          user: user,
                        });
                      })
                      .then(() => history.goBack())
                      .catch((e) => handleError(e, userContext));
                  }}
                >
                  Save
                </IonButton>
              </div>
              <Modal
                isOpen={modalState.isVisible}
                onDismiss={handleModalDismiss}
                title={modalState.isVisible ? modalState.title : ""}
                dismiss={modalState.isVisible ? modalState.dismiss : undefined}
              >
                {modalState.isVisible ? modalState.content : ""}
              </Modal>
            </div>
          </div>
        </main>
      </div>
    </IonPage>
  );
}

function patchDescriptionPreferences(
  description: string,
  token: string,
): Promise<HttpResponse> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/user/preferences/description`,
    headers: {
      Authorization: token,
    },
    body: {
      description: description,
    },
  });
}
