export function dataUrlToFile(
  dataUrl: string,
  filename: string,
): Promise<File> {
  const arr = dataUrl.split(",");
  if (arr.length < 2) {
    return Promise.reject();
  }
  const mimeArr = arr[0].match(/:(.*?);/);
  if (!mimeArr || mimeArr.length < 2) {
    return Promise.reject();
  }
  const mime = mimeArr[1];
  return srcToFile(dataUrl, filename, mime);
}

async function srcToFile(
  src: string,
  fileName: string,
  mimeType: string,
): Promise<File> {
  const res = await fetch(src);
  const buf: ArrayBuffer = await res.arrayBuffer();
  return new File([buf], fileName, { type: mimeType });
}
