import { AppMetaData } from "nativebridge";

export type Config = {
  maintenanceMode: boolean;
  forceUpdate: boolean;
  latestVersion?: AppsVersionInfo;
  deprecatedVersion?: AppsVersionInfo;
  currentNativeVersion?: AppMetaData;
  currentWebVersion?: AppMetaData;
};

export type AppsVersionInfo = {
  android: AppVersion;
  iOS: AppVersion;
  frontend: AppVersion;
};

export type AppVersion = {
  versionName: string;
  versionCode: number;
};

export function defaultConfig(): Config {
  return {
    maintenanceMode: false,
    forceUpdate: false,
    latestVersion: undefined,
    deprecatedVersion: undefined,
  };
}
