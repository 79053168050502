import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { IonAvatar, IonButton, IonImg, IonText } from "@ionic/react";
import { useContext, useState } from "react";
import { patchUserAvatar } from "../../../datasource/user-datasource";
import { compressAvatar, getAvatarFile } from "../../../util/AvatarExt";
import { UserContext } from "../../../util/BetterDatesApp";
import { handleError } from "../../../util/error";

export default function AvatarInformation(props: { onNext: () => void }) {
  const userContext = useContext(UserContext);
  if (!userContext?.userState?.loggedIn) {
    props.onNext();
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;
  const [avatar, setAvatar] = useState<AvatarStateType>({
    chosen: false,
    path: "/assets/avatar_placeholder.svg",
  });
  const [inProgress, setInProgress] = useState(false);

  const updateUsersAvatar = (avatarFile: File | Blob) => {
    patchUserAvatar(savedUser, avatarFile)
      .then((result) => {
        if (result.status !== 200) {
          return Promise.reject(result);
        }
        return result.json();
      })
      .then((body) => {
        const user = {
          ...savedUser.user,
          avatar: body.avatar,
        };
        return userContext?.loginHook.saveUser({
          ...savedUser,
          user: user,
        });
      })
      .then(() => props.onNext())
      .catch((e) => handleError(e, userContext));
  };

  return (
    <div className="flex h-full w-full flex-col justify-between px-8 pb-4">
      <div className="flex w-full flex-col gap-4">
        <IonText>
          Please select an avatar that shows your face clearly, with good
          lighting. This will help others recognize and connect with you. A
          clear face photo will ensure a great first impression!
        </IonText>
        <div className="flex w-full flex-row">
          <IonAvatar
            className="h-44 w-32"
            style={{
              "--border-radius": "4px",
            }}
          >
            <IonImg
              src={avatar.path}
              onClick={(e) => {
                e.preventDefault();
                Camera.getPhoto({
                  source: CameraSource.Photos,
                  allowEditing: false,
                  saveToGallery: false,
                  quality: 90,
                  resultType: CameraResultType.DataUrl,
                })
                  .then((result) => {
                    if (!result.dataUrl) {
                      return Promise.reject(result);
                    }
                    setAvatar({ chosen: true, path: result.dataUrl });
                  })
                  .catch((e) => handleError(e, userContext));
              }}
            />
          </IonAvatar>
        </div>
      </div>
      <div className="flex w-full flex-row justify-end">
        <IonButton
          className={"mt-8 h-9 w-20"}
          shape={"round"}
          color={"dark"}
          disabled={!avatar.chosen || inProgress}
          size={"small"}
          onClick={(e) => {
            e.preventDefault();
            setInProgress(true);
            if (!avatar.chosen) return;
            getAvatarFile(savedUser.user.id, avatar.path)
              .then((avatarFile) => {
                if (!avatarFile) {
                  return Promise.reject();
                }
                return compressAvatar(avatarFile);
              })
              .then((compressedAvatar) => {
                updateUsersAvatar(compressedAvatar);
              })
              .catch((error) => handleError(error, userContext))
              .finally(() => {
                setInProgress(false);
              });
          }}
        >
          Next
        </IonButton>
      </div>
    </div>
  );
}

interface AvatarState {
  chosen: boolean;
  path: string;
}

interface AvatarStateChosen extends AvatarState {
  chosen: true;
}

interface AvatarStateEmpty extends AvatarState {
  chosen: false;
}

type AvatarStateType = AvatarStateChosen | AvatarStateEmpty;
