import {
  IonAvatar,
  IonChip,
  IonImg,
  IonLabel,
  IonRouterLink,
} from "@ionic/react";
import React, { useContext } from "react";
import { UserContext } from "../util/BetterDatesApp";
import { JIBI_BASE_URL } from "../util/ApiClient";
import Avatar from "./Avatar";

const Header = () => {
  const userContext = useContext(UserContext);
  return (
    <header className="flex h-14 flex-row items-center justify-center px-3">
      <div className="w-full flex flex-row">
        <IonImg
          src="/assets/betterdates.svg"
          alt="BetterDates Logo"
          className="w-24 h-6"
        />
      </div>
      <div className="flex flex-none justify-center">
        <IonRouterLink routerLink="/edit-profile" routerDirection="forward">
          <IonChip>
            <IonAvatar>
              {userContext?.userState?.loggedIn && (
                <Avatar
                  src={`${JIBI_BASE_URL}/files/_pb_users_auth_/${userContext.userState.savedUser.user.id}/${userContext.userState.savedUser.user.avatar}`}
                  alt="Profile Picture"
                />
              )}
            </IonAvatar>
            <IonLabel>Profile</IonLabel>
          </IonChip>
        </IonRouterLink>
      </div>
    </header>
  );
};

export default Header;
