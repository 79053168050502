import { ToastPosition } from "@ionic/core/dist/types/components/toast/toast-interface";

export interface ToastState {
  isOpen: boolean;
  message: string;
  duration: number;
  position: ToastPosition;
}

export function defaultToastState(): ToastState {
  return {
    isOpen: false,
    message: "",
    duration: 2000,
    position: "bottom",
  };
}
