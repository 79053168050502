import { HttpResponse } from "@capacitor/core";
import {
  IonButton,
  IonChip,
  IonContent,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonText,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import RegistrationStepper from "../../../components/RegistrationStepper";
import Gender from "../../../data/Gender";
import { GET, JIBI_BASE_URL, PATCH } from "../../../util/ApiClient";
import { UserContext } from "../../../util/BetterDatesApp";
import { handleError } from "../../../util/error";
import { useHistory } from "react-router";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";

export default function GenderInformation() {
  const userContext = useContext(UserContext);
  const history = useHistory();
  if (!userContext?.userState?.loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;
  const [state, setState] = useState<GenderStateType>({ loading: true });
  const [gender, setGender] = useState<Gender | undefined>(undefined);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    fetchGenders(savedUser.token)
      .then((result) => {
        if (result.status !== 200) {
          return Promise.reject(result);
        }
        const genders = result.data.items as Gender[];
        setState({ loading: false, genders: genders });
      })
      .catch((e) => handleError(e, userContext));
  }, []);

  useEffect(() => {
    if (state.loading) {
      return;
    }
    const genders = state.genders;
    const userGenderId = savedUser.user.gender;
    const usersGender = genders.find((value) => {
      return value.id === userGenderId;
    });
    setGender(usersGender);
  }, [state]);

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          {state.loading ? (
            <div className="flex h-full w-full flex-col items-center justify-center">
              <IonText>Loading...</IonText>
            </div>
          ) : (
            <>
              <header className="flex flex-col gap-8 px-8 py-8">
                <div>
                  <IonChip
                    onClick={(e) => {
                      e.preventDefault();
                      history.goBack();
                    }}
                  >
                    <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
                    <IonLabel>Back</IonLabel>
                  </IonChip>
                </div>
                <RegistrationStepper steps={2} completedUntil={0} />
                <div className="flex w-full flex-row items-start">
                  <IonText className="text-2xl font-bold">
                    How do you identify?
                  </IonText>
                </div>
              </header>
              <main className="h-full w-full">
                <div className="flex h-full w-full flex-col justify-between px-8 pb-8">
                  {state.loading ? (
                    <div className="flex h-full w-full items-center justify-center">
                      <IonText>Loading...</IonText>
                    </div>
                  ) : (
                    <IonContent
                      className="scrollable text-gray-800"
                      scrollY={false}
                    >
                      <IonRadioGroup
                        value={gender}
                        onIonChange={(e) => {
                          setGender(e.detail.value);
                        }}
                      >
                        <div className="scrollable flex h-full w-full flex-col gap-10 overflow-y-scroll no-scrollbar">
                          {state.genders.map((value) => (
                            <div className="scrollable" key={value.id}>
                              <IonRadio
                                value={value}
                                className="scrollable"
                                labelPlacement="end"
                                color="dark"
                              >
                                {value.name}
                              </IonRadio>
                            </div>
                          ))}
                        </div>
                      </IonRadioGroup>
                    </IonContent>
                  )}
                  <div className="flex w-full flex-row justify-end">
                    <IonButton
                      className={"mt-8 h-9 w-20"}
                      shape={"round"}
                      color={"dark"}
                      disabled={gender === undefined || inProgress}
                      size={"small"}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!gender) return;
                        setInProgress(true);
                        updateGender(gender, savedUser.token)
                          .then((result) => {
                            if (result.status !== 200) {
                              return Promise.reject(result);
                            }
                            const user = {
                              ...savedUser.user,
                              gender: gender.id,
                            };
                            return userContext?.loginHook.saveUser({
                              ...savedUser,
                              user: user,
                            });
                          })
                          .then(() => history.replace("/preferences/gender"))
                          .catch((e) => handleError(e, userContext))
                          .finally(() => {
                            setInProgress(false);
                          });
                      }}
                    >
                      Next
                    </IonButton>
                  </div>
                </div>
              </main>
            </>
          )}
        </div>
      </div>
    </IonPage>
  );
}

interface GenderState {
  loading: boolean;
}

interface GenderStateLoaded extends GenderState {
  loading: false;
  genders: Gender[];
}

interface GenderStateLoading extends GenderState {
  loading: true;
}

type GenderStateType = GenderStateLoading | GenderStateLoaded;

function fetchGenders(token: string): Promise<HttpResponse> {
  return GET({
    url: `${JIBI_BASE_URL}/collections/genders_view/records`,
    headers: {
      Authorization: token,
    },
  });
}

function updateGender(gender: Gender, token: string): Promise<HttpResponse> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/user/gender`,
    headers: {
      Authorization: token,
    },
    body: {
      gender: gender.id,
    },
  });
}
