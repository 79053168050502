import { Preferences } from "@capacitor/preferences";
import { UserStateType } from "./UserContextHook";
import User from "../data/User";

export default interface LoginHook {
  saveUser: (user: SavedUser) => Promise<SavedUser>;
  logoutUser: () => Promise<void>;
}

export interface SavedUser {
  isProfileComplete: boolean;
  token: string;
  user: User;
}

export type LoginHookInternals = {
  setCurrentUser: (state: UserStateType) => void;
  logoutUser: () => void;
};

export function useLoginHook(
  loginHookInternals: LoginHookInternals,
): LoginHook {
  return {
    saveUser: async (user) => {
      await Preferences.set({
        key: USER_PREFERENCES,
        value: JSON.stringify(user, null, 0),
      });
      loginHookInternals.setCurrentUser({
        loggedIn: true,
        savedUser: user,
      });
      return user;
    },
    logoutUser: async () => {
      await Preferences.remove({ key: USER_PREFERENCES });
      loginHookInternals.setCurrentUser({ loggedIn: false });
    },
  };
}

export const USER_PREFERENCES = "user";
