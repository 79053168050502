import { HttpResponse } from "@capacitor/core";
import { ChevronLeftIcon, PencilSquareIcon } from "@heroicons/react/20/solid";
import { EllipsisHorizontalCircleIcon } from "@heroicons/react/24/outline";
import {
  IonActionSheet,
  IonButton,
  IonChip,
  IonContent,
  IonFab,
  IonFabButton,
  IonItem,
  IonLabel,
  IonPage,
  IonRippleEffect,
  IonText,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import MatchState from "../../components/MatchState";
import Modal, { ModalStateType } from "../../components/modal/Modal";
import ReportModal from "../../components/modal/ReportModal";
import Letter from "../../data/Letter";
import LettersResponse from "../../data/LettersResponse";
import { Match } from "../../data/Match";
import { InMemoryCache } from "../../hooks/CacheHook";
import { SavedUser } from "../../hooks/LoginHook";
import "../../theme/variables.css";
import { GET, JIBI_BASE_URL } from "../../util/ApiClient";
import { UserContext } from "../../util/BetterDatesApp";
import { handleError } from "../../util/error";

export default function ListOfLetters() {
  const params = useParams<ListOfLettersProps>();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const [state, setState] = useState<ListOfLettersStateType>({ loading: true });
  const [isActionSheetOpen, setisActionSheetOpen] = useState(false);
  const [modalState, setModalState] = useState<ModalStateType>({
    isVisible: false,
  });

  if (!userContext?.userState.loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;

  useEffect(() => {
    const cachedLetters = userContext.cache.letters.get(params.matchId);
    const cachedMatch = getCachedMatch(userContext.cache, params.matchId);
    if (cachedLetters && cachedMatch) {
      setState({
        loading: false,
        letters: cachedLetters,
        match: cachedMatch,
      });
    } else {
      fetchLetters(params.matchId, savedUser)
        .then((result) => {
          if (result.status !== 200 && result.status !== 404) {
            return Promise.reject(result);
          }
          if (result.status === 404) {
            setState({ loading: false, letters: [] });
          } else {
            const response = result.data as LettersResponse;
            userContext.cacheHook.cacheLetters(
              params.matchId,
              response.letters,
            );
            const oldMatches =
              userContext.cache.matches?.filter((value) => {
                return value.id !== params.matchId;
              }) || [];
            userContext.cacheHook.cacheMatches([...oldMatches, response.match]);
            setState({
              loading: false,
              letters: response.letters,
              match: response.match,
            });
          }
        })
        .catch((e) => handleError(e, userContext));
    }
  }, []);

  const handleModalDismiss = () => {
    setModalState({
      isVisible: false,
    });
  };

  const dateFormatter = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          <header className="flex h-14 flex-row items-center justify-between px-3">
            <IonChip
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
              <IonLabel>Back</IonLabel>
            </IonChip>
            {isListOfLettersStateLoaded(state) && (
              <>
                <div
                  className="ion-activatable relative flex h-10 w-10 items-center justify-center overflow-hidden rounded-full"
                  onClick={(e) => {
                    e.preventDefault();
                    setisActionSheetOpen(true);
                  }}
                >
                  <EllipsisHorizontalCircleIcon className="h-6.5 w-6.5 stroke-gray-900" />
                  <IonRippleEffect />
                </div>

                <IonActionSheet
                  isOpen={isActionSheetOpen}
                  buttons={[
                    {
                      text: "Report your match",
                      role: "destructive",
                      handler: () => {
                        setModalState({
                          isVisible: true,
                          content: (
                            <ReportModal
                              setModalState={setModalState}
                              matchId={params.matchId}
                            />
                          ),
                          title: "Report your match",
                          dismiss: "Cancel",
                        });
                      },
                    },
                    {
                      text: "Cancel",
                      role: "cancel",
                      data: {
                        action: "cancel",
                      },
                    },
                  ]}
                  onDidDismiss={() => setisActionSheetOpen(false)}
                />
              </>
            )}
          </header>
          <main className="flex h-full w-full items-center justify-center">
            {state.loading ? (
              <div className="flex h-full w-full flex-col items-center justify-center">
                <IonText>Loading...</IonText>
              </div>
            ) : (
              <>
                {(isListOfLettersStateLoaded(state) && (
                  <div className="flex h-full w-full flex-col gap-8">
                    <MatchState
                      match={state.match}
                      updateMatch={(updatedMatch) => {
                        setState((prevState) => {
                          return {
                            ...prevState,
                            match: updatedMatch,
                          };
                        });
                      }}
                      dateFormatter={dateFormatter}
                    />
                    <IonContent scrollY={true}>
                      {state.letters.map((item, index) => {
                        return (
                          <IonItem
                            button
                            detail={true}
                            key={item.id}
                            lines={"none"}
                            className={
                              index === state.letters.length - 1 ? "pb-10" : ""
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              history.push(
                                `/letter/${params.matchId}/${item.id}`,
                              );
                            }}
                          >
                            <div className="flex w-full flex-row justify-between">
                              <IonText>
                                Letter #{item.num} - From&nbsp;
                                {item.from === savedUser.user.id ? (
                                  "you"
                                ) : (
                                  <b>{state.match.partyTwo.name}</b>
                                )}
                              </IonText>
                              <IonText className="text-sm text-gray-400">
                                {dateFormatter.format(new Date(item.sendDate))}
                              </IonText>
                            </div>
                          </IonItem>
                        );
                      })}
                      <IonFab
                        vertical="bottom"
                        horizontal="end"
                        slot="fixed"
                        className="write-letter-fab"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(
                            `/write/${params.matchId}/${params.partyTwoId}`,
                          );
                        }}
                      >
                        <IonFabButton
                          color="dark"
                          className="write-letter-fab-button"
                        >
                          <PencilSquareIcon className="me-2 h-5 w-5 stroke-white" />{" "}
                          <b>WRITE</b>
                        </IonFabButton>
                      </IonFab>
                    </IonContent>
                  </div>
                )) || (
                  <div className="flex w-full flex-col items-center justify-center gap-8">
                    <IonText>They&apos;re waiting for your letter!</IonText>
                    <IonButton
                      className={"h-9 w-36"}
                      shape={"round"}
                      color={"dark"}
                      size={"small"}
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          `/write/${params.matchId}/${params.partyTwoId}`,
                        );
                      }}
                    >
                      Send a letter
                    </IonButton>
                  </div>
                )}
              </>
            )}
          </main>
        </div>
      </div>

      <Modal
        isOpen={modalState.isVisible}
        onDismiss={handleModalDismiss}
        title={modalState.isVisible ? modalState.title : ""}
      >
        {modalState.isVisible ? modalState.content : ""}
      </Modal>
    </IonPage>
  );
}

function fetchLetters(
  matchId: string,
  savedUser: SavedUser,
): Promise<HttpResponse> {
  return GET({
    url: `${JIBI_BASE_URL}/v1/letter/${matchId}`,
    headers: {
      Authorization: savedUser.token,
    },
  });
}

interface ListOfLettersState {
  loading: boolean;
}

interface ListOfLettersStateLoading extends ListOfLettersState {
  loading: true;
}

interface ListOfLettersStateLoaded extends ListOfLettersState {
  loading: false;
  letters: Letter[];
  match: Match;
}

interface ListOfLettersStateEmpty extends ListOfLettersState {
  loading: false;
  letters: [];
}

type ListOfLettersStateType =
  | ListOfLettersStateLoaded
  | ListOfLettersStateEmpty
  | ListOfLettersStateLoading;

function isListOfLettersStateLoaded(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input: any,
): input is ListOfLettersStateLoaded {
  return input.loading === false && input.letters.length > 0 && input.match;
}

function getCachedMatch(
  cache: InMemoryCache,
  matchId: string,
): Match | undefined {
  const cachedMatches = cache.matches;
  return cachedMatches?.find((element) => element.id === matchId) || undefined;
}

export type ListOfLettersProps = {
  partyTwoId: string;
  matchId: string;
};
