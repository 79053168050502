export function calculateAge(birthday: Date): number {
  const currentDate = new Date();
  let age = currentDate.getFullYear() - birthday.getFullYear();
  const monthDiff = currentDate.getMonth() - birthday.getMonth();
  // If the birthday hasn't occurred yet this year, subtract 1 from the age
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && currentDate.getDate() < birthday.getDate())
  ) {
    age--;
  }
  return age;
}
