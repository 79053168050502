import { Capacitor } from "@capacitor/core";
import { ArrowDownCircleIcon } from "@heroicons/react/24/outline";
import { IonButton, IonPage, IonText } from "@ionic/react";
import { useContext, useState } from "react";
import { UserContext } from "../../util/BetterDatesApp";
import { handleError } from "../../util/error";

export default function ForceUpdate() {
  const userContext = useContext(UserContext);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  return (
    <IonPage className="flex items-center justify-center">
      <div className="h-full w-full max-w-md">
        <main className="flex h-full w-full items-center justify-center px-8">
          <div className="flex flex-col">
            <ArrowDownCircleIcon className="h-8 w-8 stroke-black stroke-[1.5px]" />
            <IonText className="mt-4 text-xl font-bold">
              Your app needs an update!
            </IonText>
            <IonText>This version of the app is not supported anymore.</IonText>
            <div className="flex flex-row items-center justify-center">
              <IonButton
                className={"mt-8 h-9 w-28"}
                shape={"round"}
                disabled={updateInProgress}
                color={"dark"}
                size={"small"}
                onClick={(e) => {
                  e.preventDefault();
                  if (Capacitor.getPlatform() === "web") {
                    setUpdateInProgress(true);
                    userContext?.serviceWorkerHook
                      .registration()
                      ?.update()
                      .then(() => {
                        window.location.reload();
                      })
                      .catch((e) => handleError(e, userContext))
                      .finally(() => {
                        setUpdateInProgress(false);
                      });
                  }
                }}
              >
                Update
              </IonButton>
            </div>
          </div>
        </main>
      </div>
    </IonPage>
  );
}
