import { IonContent, IonItem } from "@ionic/react";
import React, { useMemo } from "react";
import CountriesJSON from "../../countries.json";
import Country from "../../data/Country";
import { ModalStateType } from "./Modal";

export default function CountriesDropDown({
  setModalState,
  onSelectCountry,
}: CountriesDropDownProps) {
  const countries = useMemo(() => CountriesJSON.countries, []);

  const handleCountrySelect = (country: Country) => {
    setModalState({ isVisible: false });
    onSelectCountry(country);
  };
  return (
    <IonContent
      className="ion-padding-bottom mx-2 mt-4 flex touch-auto flex-col overflow-y-scroll no-scrollbar"
      style={{ "--background": "#ffffff" }}
    >
      {countries.map((country, index) => (
        <IonItem
          lines={"none"}
          key={index}
          onClick={() => handleCountrySelect(country)}
          className="h-15"
        >
          <div className="flex flex-row gap-3">
            <div>{country.flag}</div>
            <div>{country.name}</div>
          </div>
        </IonItem>
      ))}
    </IonContent>
  );
}

interface CountriesDropDownProps {
  setModalState: React.Dispatch<React.SetStateAction<ModalStateType>>;
  onSelectCountry: (country: Country) => void;
}
